
import { Component } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import { SettingsGeneralService } from '@/modules/settings/settings-general.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';

import { FirstColumnType, TableRow } from '../../interfaces/day-rate-tooltip.interface';
import DayRateTooltipCompset from './day-rate-tooltip-compset.vue';

@Component({
    extends: DayRateTooltipCompset,
})
export default class DayRateTooltipHotels extends DayRateTooltipCompset {
    @inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @inject(KEY.SettingsGeneralService)
    private settingsGeneralService!: SettingsGeneralService;

    firstColumns = [FirstColumnType.LEGEND, FirstColumnType.RANK];

    mutateRoomData(roomData: TableRow[]) {
        roomData.forEach((_, index) => {
            const room = roomData[index];

            const hotelColor = !this.ratesService.isAllChannelsMode
                ? this.hotelsService.getHotelsGraphColor()[room.hotelId]
                : this.getProviderColor(room.hotelId as string);

            room.legendColor = room.hotelId !== this.hotelId
                ? hotelColor
                : CURRENT_HOTEL_GRAPH_COLOR;
        });

        return roomData;
    }

    private getProviderColor(hotelId: string) {
        const index = Object.keys(this.ratesService.getCompetitorsRooms(1)).indexOf(hotelId);

        return this.settingsGeneralService.chartColors[index];
    }
}
